import { Farm, FarmsState, State } from "state/types"
import { useSelector } from "react-redux"
import { BigNumber } from "bignumber.js"
import { BIG_ZERO } from "utils/bigNumber"

export const useFarms = (): FarmsState => {
    const farms = useSelector((state: State) => state.farms)
    return farms
}

export const usePriceBnbBusd = (): BigNumber => {
    const bnbBusdFarm = useFarmFromPid(18);
    
    return bnbBusdFarm?.tokenPriceVsQuote ? new BigNumber(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO
}

export const usePricePonyBusd = (): BigNumber => {
    const ponyBusdPrice = useFarmFromPid(16)
    
    return ponyBusdPrice.tokenPriceVsQuote ? new BigNumber(ponyBusdPrice.tokenPriceVsQuote) : BIG_ZERO
}

export const useFarmFromPid = (pid): Farm => {
    const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid));
    return farm as Farm
}