import useTheme from "hooks/useTheme";
import React, { useCallback, useState } from "react";
import { Button } from "@ponyswapdex/uikit";
import { AutoColumn } from "components/Column";
import { BigNumber } from "bignumber.js";
import { useTokenContract } from "hooks/useContract";
import { Text } from "rebass";
import { useApproveLP } from "hooks/useApprove";
import ConnectButtonWallet from "components/ConnectWalletButton";
import { useLocation } from "react-router-dom";
import { provider as ProviderType } from 'web3-core'
import { getAddress } from "utils/addressHelpers";
import useWeb3 from "hooks/useWeb3";
import { Contract } from '@ethersproject/contracts'
import { FarmWithStakedValue } from ".";
import StakeAction from "./StakeAction";
import HarvestAction from "./HarvestAction";


interface FarmCardActionsProps {
  stable: FarmWithStakedValue
  provider?: ProviderType
  account?: string
  addLiquidityUrl?: string
}

export default ({stable, account, addLiquidityUrl, provider}: FarmCardActionsProps) => {

    const {theme} = useTheme();
    const [requestedApproval, setRequestedApproval] = useState(false)
    const { pid, lpAddresses } = stable
    const {
      allowance: allowanceAsString = 0,
      tokenBalance: tokenBalanceAsString = 0,
      stakedBalance: stakedBalanceAsString = 0,
      earnings: earningsAsString = 0,
    } = stable.userData || {}
    const allowance = new BigNumber(allowanceAsString)
    const tokenBalance = new BigNumber(tokenBalanceAsString)
    const stakedBalance = new BigNumber(stakedBalanceAsString)
    const earnings = new BigNumber(earningsAsString)
    const lpAddress = getAddress(lpAddresses)
    const lpName = stable.lpSymbol.toUpperCase()
    const isApproved = account && allowance && allowance.isGreaterThan(0)
    const web3 = useWeb3()
    const location = useLocation()

    const lpContract = useTokenContract(lpAddress)
    
    const { onApprove } = useApproveLP(lpContract as Contract)

    const handleApprove = useCallback(async () => {
        try {
          setRequestedApproval(true)
          await onApprove()
          setRequestedApproval(false)
        } catch (e) {
          console.error(e)
        }
      }, [onApprove])

      const renderApprovalOrStakeButton = () => {
      //   return isApproved ? (
      //     <StakeAction
      //       stakedBalance={stakedBalance}
      //       tokenBalance={tokenBalance}
      //       tokenName={lpName}
      //       pid={pid}
      //       addLiquidityUrl={addLiquidityUrl}
      //     />
      //   ) : (
      //     <Button
      //       fullWidth
      //       disabled={requestedApproval || location.pathname.includes('archived')}
      //       onClick={handleApprove}
      //     >
      //       Approve Contract
      //     </Button>
      //   )
      // }
        return <Button fullWidth disabled>Coming soon</Button>
    }

    return(
        <>
            <AutoColumn pt={20}>
                <Text fontSize={13} mb={1} fontWeight="bold">PONY <span style={{color: theme?.colors.primary}}>EARNED</span></Text>
                <HarvestAction earnings={earnings} pid={pid} /> 
            </AutoColumn>
            <AutoColumn mt={2}>
                {!account ? <ConnectButtonWallet fullWidth /> : renderApprovalOrStakeButton()}
            </AutoColumn>
        </>        
    )
}