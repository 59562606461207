import React from "react";
import Row, {RowBetween} from "components/Row";
import DoubleCurrencyLogo from "components/DoubleLogo";
import {Flex, Text} from "rebass";
import {Tag} from "@ponyswapdex/uikit";
import {Currency} from "@pantherswap-libs/sdk";

export interface HeadingPoolCardProps {
    label: string;
    multiplier: string;
    currency0: Currency;
    currency1: Currency;
    noFees?: boolean;
}

export default ({label, multiplier, currency0, currency1, noFees}: HeadingPoolCardProps) => (
    <RowBetween>
        <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={50} />
        <Flex alignItems="end" flexDirection="column">
            <Text fontSize={3} fontWeight="bold" mb={2} style={{textTransform: "uppercase"}}>{label}</Text>
            <Row justify="end" gap='3px'>
                {noFees && <Tag outline variant="success">0% fee</Tag>}
                <Tag variant="primary">{multiplier}</Tag>
            </Row>
        </Flex>
    </RowBetween>
)