import Card, { LightCard } from "components/Card";
import PageBanner from "components/PageBanner";
import Row, { RowBetween } from "components/Row";
import React from "react";
import {FaTelegramPlane, FaTwitter, FaDiscord, FaRetweet, FaWpforms, FaHeart} from "react-icons/fa";
import { Box, Flex } from "rebass";
import { Button, CardBody, Heading, Image, Tag, Text, CardHeader } from "@ponyswapdex/uikit";
import {useTimer} from "react-timer-hook";

export default () => {
    const {
        seconds,
        minutes,
        hours,
        days,
      } = useTimer({ expiryTimestamp: new Date('03/16/2022 20:00:00'), onExpire: () => console.warn('onExpire called') });

    return (
        <>
            <PageBanner bgColor="#1a1e2b" description="Follow these steps to get a free random amount of PONY" title='Free airdrop' />
            <Flex padding={['0 20px', '0 20px', '0 20px', '0 90px']} justifyContent="center" width={[1, 1, 1, 1]}>
                <Row wrap="wrap" align="start" gap="30px">
                    <Flex order={[1, 1, 1, 0]} flex={['0 1 100%', '0 1 100%', '0 1 100%', '0 1 65%']}>
                        <Card>
                            <CardHeader>
                                <Row gap="15px">
                                    <Image src='/images/192x192_App_Icon.png' height={90} width={90} />
                                    <Box>
                                        <Tag variant="primary">Ongoing</Tag>
                                        <Heading mt={2} fontSize="24px !important">PONY Airdrop</Heading>
                                    </Box>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Text mb={3}>PonySwap launches officially before the end of March 2022. but theres still time to participate in the PONY Airdrop.</Text>
                                <Heading mb={3}>Step 1 :</Heading>
                                <Text mb={3}>Join our telegram channel</Text>
                                <Button as="a" target="_blank" href="https://t.me/ponyswapEN" mb={3} startIcon={<FaTelegramPlane style={{marginRight: "5px"}} />} style={{backgroundColor: "#0088CC", color: "white"}}>Join telegram</Button>
                                <Heading  mb={3}>Step 2 :</Heading>
                                <Text mb={3}>Join our discord community</Text>
                                <Button as="a" target="_blank"  href="https://discord.gg/dneQkh3883" mb={3} size='md' startIcon={<FaDiscord style={{marginRight: "5px"}} />} style={{backgroundColor: "#5865F2", color: "white"}}>Join discord</Button>
                                <Heading  mb={3}>Step 3 :</Heading>
                                <Text mb={3}>Follow us on Twitter</Text>    
                                <Button as="a" target="_blank"  href="https://twitter.com/intent/follow?screen_name=ponyswapDEX" mb={3} size='md' startIcon={<FaTwitter style={{marginRight: "5px"}} />} style={{backgroundColor: "#1DA1F2", color: "white"}}>Follow us on Twitter</Button> 
                                <Heading  mb={3}>Step 4 :</Heading>
                                <Text mb={3}>Like and Retweet the airdrop tweet</Text>
                                <Button mr={3} as="a" target="_blank" href="https://twitter.com/intent/like?tweet_id=1501613039166046215"  mb={3} size='md' startIcon={<FaHeart style={{marginRight: "5px"}} />} style={{backgroundColor: "#1DA1F2", color: "white"}}>Like</Button>
                                <Button as="a" target="_blank" href="https://twitter.com/intent/retweet?tweet_id=1501613039166046215"  mb={3} size='md' startIcon={<FaRetweet style={{marginRight: "5px"}} />} style={{backgroundColor: "#1DA1F2", color: "white"}}>Retweet</Button>
                                <Heading  mb={3}>Step 5 :</Heading>
                                <Text mb={3}>Fill this form with your BSC wallet address</Text>
                                <Button as="a" href="https://forms.gle/1gHQL2ChKH2smL2h8" target="_blank" mb={3} size='md' startIcon={<FaWpforms style={{marginRight: "5px"}} />} variant="primary">Fill the form</Button>                           
                            </CardBody>
                        </Card>
                    </Flex>
                    <Box order={[0, 0, 0, 1]} flex={['0 1 100%', '0 1 100%', '0 1 100%', '1 0 calc(35% - 30px)']}>
                        <LightCard>
                            <CardHeader>
                                <Heading>Info</Heading>
                            </CardHeader>
                            <CardBody>
                                <RowBetween mb={10}>
                                    <Text>Max airdrop</Text>
                                    <Text>1,000</Text>
                                </RowBetween>
                                <RowBetween mb={10}>
                                    <Text>Time left</Text>
                                    <Text>{days}d {hours}h {minutes}m {seconds}s</Text>
                                </RowBetween>
                                
                            </CardBody>
                        </LightCard>
                    </Box>
                </Row>
            </Flex>
        </>
    )
}