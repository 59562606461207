import React, { useCallback } from "react";
import CryptoJS from "crypto-js";
import Secret from ".secret";

export default function useCryptoJS() {

    const encrypt = useCallback((data: string) => {
        const plaintext = CryptoJS.enc.Utf8.parse(data);
        let secSpec = CryptoJS.enc.Utf8.parse(Secret.cryptosecret);    
        let ivSpec = CryptoJS.enc.Utf8.parse(Secret.cryptosecret);
    
        secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 32/4));
        ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 32/4));
    
        return CryptoJS.AES.encrypt(plaintext, secSpec, {iv: ivSpec});
    }, []);

    const decrypt = useCallback((data: string) => {
        // const ciphertext = CryptoJS.enc.Base64.parse(data);
        let secSpec = CryptoJS.enc.Utf8.parse(Secret.cryptosecret);
        let ivSpec = CryptoJS.enc.Utf8.parse(Secret.cryptosecret);
        
        secSpec = CryptoJS.lib.WordArray.create(secSpec.words.slice(0, 32/4));
        ivSpec = CryptoJS.lib.WordArray.create(ivSpec.words.slice(0, 32/4));

        return CryptoJS.AES.decrypt(data, secSpec, {iv: ivSpec}).toString(CryptoJS.enc.Utf8);
    }, []);

    return {encrypt, decrypt};
}