import { Alert, Button, CardBody, CardHeader, ToastContainer, Toast} from "@ponyswapdex/uikit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Flex, Text } from "rebass";
import useTheme from "hooks/useTheme";
import { AutoColumn, ColumnCenter } from "components/Column";
import Row, {AutoRow, RowFlat} from "components/Row";
import PageBanner from "components/PageBanner";
import { useActiveWeb3React } from "hooks";
import ConnectWalletButton from "components/ConnectWalletButton";
import useCryptoJS from "hooks/useCryptoJS";
import { useContract } from "hooks/useContract";
import REFERRAL_ABI from "constants/abis/PonyReferral.json";
import {Contract } from "ethers";
import {BigNumber} from "bignumber.js";
import StablesCard from "components/Stables";
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, setLoadArchivedFarmsData } from "state/farms";
import { useFarms, usePricePonyBusd } from "state/farms/hooks";
import useRefresh from "hooks/useRefresh";
import { useLocation } from "react-router-dom";
import isArchivedPid from "utils/farmHelpers";
import { useGetApiPrices } from "state/application/hooks";
import { Farm } from "state/types";
import { FarmWithStakedValue } from "components/Stables/StablesCard";
import { getFarmApr, getPoolApr } from "utils/apr";
import { useDispatch } from "react-redux";
import { getAddress } from "utils/addressHelpers";
import { Address } from "constants/types";
import tokens from "constants/tokens";
import { getBalanceNumber } from "utils/formatBalance";

const StablesPage = () => {
    const {account} = useActiveWeb3React();
    const dispatch = useDispatch();
    const { data: stablesLP, userDataLoaded } = useFarms() || {};
    const {pathname} = useLocation();
    const prices = useGetApiPrices();
    const ponyPrice = usePricePonyBusd();
    const [query, setQuery] = useState('')

    const fastRefresh = useRefresh();
    useEffect(() => {
        if (account) {
          dispatch<any>(fetchFarmUserDataAsync(account));
        }
    }, [account, dispatch, fastRefresh])
    
    const isArchived = pathname.includes('archived')
    const isInactive = pathname.includes('history')
    const isActive = !isInactive && !isArchived

    const userDataReady = !account || (!!account && userDataLoaded)
      
    const [stakedOnly, setStakedOnly] = useState(!isActive);

    useEffect(() => {
        setStakedOnly(!isActive)
    }, [isActive])
    
      useEffect(() => {
        // Makes the main scheduled fetching to request archived farms data
        dispatch(setLoadArchivedFarmsData(isArchived))
    
        // Immediately request data for archived farms so users don't have to wait
        // 60 seconds for public data and 10 seconds for user data
        if (isArchived) {
          dispatch<any>(fetchFarmsPublicDataAsync());
          if (account) {
            dispatch<any>(fetchFarmUserDataAsync(account))
          }
        }
      }, [isArchived, dispatch, account]);

  const activeStables = stablesLP.filter((stable) => stable.pid !== 0 && stable.multiplier !== '0X' && !isArchivedPid(stable.pid))
  const inactiveStables = stablesLP.filter((stable) => stable.pid !== 0 && stable.multiplier === '0X' && !isArchivedPid(stable.pid))
  const archivedStables = stablesLP.filter((stable) => isArchivedPid(stable.pid))

  const stakedOnlyStables = activeStables.filter(
    (stable) => stable.userData && new BigNumber(stable.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedInactiveStables = inactiveStables.filter(
    (stable) => stable.userData && new BigNumber(stable.userData.stakedBalance).isGreaterThan(0),
  )

  const stakedArchivedStables = archivedStables.filter(
    (stable) => stable.userData && new BigNumber(stable.userData.stakedBalance).isGreaterThan(0),
  )
  
  const stablesList = useCallback(
    (stablesToDisplay: Farm[]): FarmWithStakedValue[] => {
      let stablesToDisplayWithAPR: FarmWithStakedValue[] | any = stablesToDisplay.map((stable) => {
        if (!stable.lpTotalInQuoteToken || !prices) {
          return stable
        }

        const quoteTokenPriceUsd = prices[getAddress(stable.quoteToken.address as Address).toLowerCase()];
        const totalLiquidity = new BigNumber(stable.lpTotalInQuoteToken).times(quoteTokenPriceUsd);
        const apr = isActive ? getFarmApr(stable.poolWeight as BigNumber, ponyPrice, totalLiquidity) : 0  

        return { ...stable, apr, liquidity: totalLiquidity }
      })

      if (query) {
        const lowercaseQuery = query.toLowerCase();
        stablesToDisplayWithAPR = stablesToDisplayWithAPR.filter((stable: FarmWithStakedValue) => {
          return stable.lpSymbol.toLowerCase().includes(lowercaseQuery);
        })
      }
	  
      return stablesToDisplayWithAPR
    },[ponyPrice, prices, query, isActive])

    return(
        <>
            <Flex alignSelf="start" width="100%" flexDirection="column" alignItems="center" height='100%'>
                <PageBanner bgColor="#1a1e2b" title="Stables" description="Stake LP Tokens to earn PONY" />
                <AutoColumn maxWidth='1320px' width='100%' px={4}> 
                    <Row wrap="wrap" justify="center" gap="30px 15px">
                      {stablesList(activeStables).map((stable: FarmWithStakedValue) => {
                        return <StablesCard key={stable.pid} stable={stable} withRainbow={stable.pid === 17} />
                      })}
                    </Row>
                </AutoColumn>
            </Flex>
         </>
    )

}

export default StablesPage;