import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pantherswap-libs/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import { MASTERCHEF_ADDRESS } from 'constants/index'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import MASTERCHEF_ABI from '../constants/abis/masterchef.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()
  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.BSCTESTNET:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useMasterchef(withSignerIfPossible?: boolean): Contract | null {
  return useContract(MASTERCHEF_ADDRESS, MASTERCHEF_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}


export async function getContractABI(address: string){

  const url = `https://api.bscscan.com/api?module=contract&action=getabi&address=${address}&apikey=Q9ZQ3W73JY63ATR9Y5AIXZTGA3Q68TCZ1C`
  const response = await fetch(url)
  const data = await response.json()
  return data.result

}

export const callContractMethod = async (contractInstance: Contract, methodName: string, options: any = {}) => {
  let resultOfCall: any;
  try{
      switch(methodName){
          case "getAmountsOut":
              resultOfCall = await contractInstance.functions[methodName](options.amountTokenIn, [options.tokenIn, options.tokenOut])
              break;
          default:
              resultOfCall = await contractInstance.functions[methodName]()
              break;
      }
  }catch(err){
      // console.log('error', methodName, options)
      // console.log(err)
      return false
  }

  return resultOfCall
}

export async function getTracker(address: string, ABI: string, account: string, library: any, withSignerIfPossible = true) {
  const tokenContractInstance: Contract = getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
  const tracker = await callContractMethod(tokenContractInstance, "dividendTracker");
  return tracker
}
