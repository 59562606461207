import tokens from '../tokens';
import { FarmConfig } from '../types'

export const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  // {
  //   pid: 0,
  //   lpSymbol: 'PANTHER-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x937ced19Daa8a057e7F4062e93EA4AEDAd730E1c',
  //   },
  //   token: tokens.panther,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 1,
  //   lpSymbol: 'PANTHER-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xecc11a78490866e0073EbC4a4dCb6F75673C8685',
  //   },
  //   token: tokens.panther,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 2,
  //   lpSymbol: 'BNB-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   },
  //   token: tokens.wbnb,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 3,
  //   lpSymbol: 'BTCB-BNB LP',
  //   lpAddresses:{
  //     97: '',
  //     56: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
  //   },
  //   token: tokens.btcb,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 4,
  //   lpSymbol: 'ETH-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
  //   },
  //   token: tokens.eth,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 5,
  //   lpSymbol: 'DOT-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
  //   },
  //   token: tokens.dot,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 6,
  //   lpSymbol: 'USDT-BNB LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
  //   },
  //   token: tokens.usdt,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 8,
  //   lpSymbol: 'CAKE-BNB LP',
  //   lpAddresses:{
  //     97: '',
  //     56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
  //   },
  //   token: tokens.cake,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 9,
    lpSymbol: 'PANTHER',
    lpAddresses:{
      97: '',
      56: '0x058451C62B96c594aD984370eDA8B6FD7197bbd4',
    },
    token: tokens.panther,
    quoteToken: tokens.panther,
  },
  // {
  //   pid: 10,
  //   lpSymbol: 'BUSD',
  //   lpAddresses:{
  //     97: '',
  //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   },
  //   token: tokens.busd,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 11,
  //   lpSymbol: 'BNB',
  //   lpAddresses:{
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   token: tokens.wbnb,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 13,
  //   lpSymbol: 'ETH',
  //   lpAddresses:{
  //     97: '',
  //     56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   },
  //   token: tokens.eth,
  //   quoteToken: tokens.eth,
  // },
  {
    pid: 16,
    lpSymbol: 'PONY-BUSD LP',
    lpAddresses:{
      97: '',
      56: '0x9287F5Ad55D7eE8EAE90B865718EB9A7cF3fb71A',
    },
    token: tokens.panther,
    quoteToken: tokens.busd,
  },
  {
    pid: 17,
    lpSymbol: 'PONY-BNB LP',
    lpAddresses:{
      97: '',
      56: '0xC24AD5197DaeFD97DF28C70AcbDF17d9fF92a49B',
    },
    token: tokens.panther,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 18,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses:{
      97: '',
      56: '0x6AF4C4433474B2F8BA385AD62B23299c82846783',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },
]