import {ToastContainer, Toast} from "@ponyswapdex/uikit";
import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Flex, Text } from "rebass";
import useTheme from "hooks/useTheme";
import { AutoColumn, ColumnCenter } from "components/Column";
import PageBanner from "components/PageBanner";
import { usePools } from "state/pools/hooks";
import { useWeb3React } from "@web3-react/core";
import { useRouteMatch } from "react-router-dom";
import { BigNumber } from "bignumber.js";
import partition from 'lodash/partition'
import { useBlock } from "state/block/hooks";
import { Pool } from "state/types";

const BarnPage = () => {

    const {theme} = useTheme();
    const {account}: any = useWeb3React();
    const pools = usePools(account);
    
    const [toasts, setToasts] = React.useState<Toast[]>([]);

    const handleClick = () => {
        const now = Date.now();
        const randomToast: Toast = {
            id: `id-${now}`,
            title: `Copied to clipboard`,
            type: 'info',
        };

        setToasts((prevToasts: any) => [randomToast, ...prevToasts]);
    };

    const handleRemove = (id: string) => {
        setToasts((prevToasts: any) => prevToasts.filter((prevToast) => prevToast.id !== id));
    };

    const { path } = useRouteMatch()
  const { currentBlock } = useBlock()
  
  const [finishedPools, openPools] = useMemo(
    () => partition(pools, (pool: Pool) => pool.isFinished || currentBlock > (pool.endBlock ?? 0)),
    [currentBlock, pools],
  )
//   const stakedOnlyPools = useMemo(
//     () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
//     [openPools],
//   )
//   const hasStakeInFinishedPools = useMemo(
//     () => finishedPools.some((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
//     [finishedPools],
//   )
  // This pool is passed explicitly to the cake vault
    const cakePoolData = useMemo(() => openPools.find((pool) => pool.sousId === 9), [openPools])
    console.log(cakePoolData)
    return(
        <>
        <Flex alignSelf="start" width="100%" flexDirection="column" alignItems="center" height='100%'>
            <PageBanner bgColor="#1a1e2b" title="The barn" description="Stake Tokens to earn PONY" />
            <AutoColumn gap="40px" maxWidth='1220px' width='100%' px={4}> 
                <>
                
                </>
            </AutoColumn>
        </Flex>
         <ToastContainer toasts={toasts} onRemove={handleRemove} />
         </>
    )

}

export default BarnPage;