import { useEffect } from "react"
import { Pool, State } from "state/types"
import { useDispatch, useSelector } from "react-redux"
import useRefresh from "hooks/useRefresh"
import { fetchPoolsUserDataAsync } from "."


export const usePools = (account): Pool[] => {
    const { fastRefresh } = useRefresh()
    const dispatch = useDispatch()
    useEffect(() => {
        if (account) {
        dispatch(fetchPoolsUserDataAsync(account))
        }
    }, [account, dispatch, fastRefresh])

    const pools = useSelector((state: State) => state.pools.data)
    return pools
}

export const usePoolFromPid = (sousId): Pool => {
    const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId)) as Pool;
    return pool
}