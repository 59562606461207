import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchPrices } from '.'

// Price selectors
export const useFetchPriceList = () => {
    const { slowRefresh } = useRefresh()
    const dispatch = useDispatch()
  
    useEffect(() => {
      dispatch(fetchPrices() as any)
    }, [dispatch, slowRefresh])
  }