import {ETHER, Token } from "@pantherswap-libs/sdk";
import { Card, CardBody, Skeleton, Tag } from "@ponyswapdex/uikit";
import { AutoColumn } from "components/Column";
import { RowBetween } from "components/Row";
import { BigNumber } from "ethers";
import React, { useMemo } from "react";
import { Box, Flex, Text } from "rebass";
import styled, {css, keyframes} from "styled-components";
import getLiquidityUrlPathParts from "utils/getLiquidityUrlPathParts";
import { usePricePonyBusd } from "state/farms/hooks";
import { useWeb3React } from "@web3-react/core";
import { DisabledCard } from "components/Card";
import { FarmWithStakedValue } from "./StablesCard";
import CardActions from "./StablesCard/CardActions";
import Heading from "./StablesCard/Heading";
import PaddockTable from "./StablesTable";
import ApyButton from "./StablesCard/ApyButton";


interface StableCardProps {
    stable: FarmWithStakedValue;
    withRainbow?: boolean;
}

const keyframe = keyframes`
    0% { background-position: 0% 50%; }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const animation = props => css`
    2s linear infinite normal none running ${keyframe};
`

const StableCard = styled(Card)`
    overflow: initial;
    >.rainbow{
        animation: ${animation};
        filter: blur(6px);
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -2px;
        z-index: -1;
        background: linear-gradient(45deg, rgb(255, 0, 0) 0%, rgb(255, 154, 0) 10%, rgb(208, 222, 33) 20%, rgb(79, 220, 74) 30%, rgb(63, 218, 216) 40%, rgb(47, 201, 226) 50%, rgb(28, 127, 238) 60%, rgb(95, 21, 242) 70%, rgb(186, 12, 248) 80%, rgb(251, 7, 217) 90%, rgb(255, 0, 0) 100%) 0% 0% / 300% 300%;
        border-radius: 16px;
    }
    
`;


export default ({stable, withRainbow}: StableCardProps) => {

    const ponyPrice = usePricePonyBusd();
    const {account} = useWeb3React();
    
    const currency0 = useMemo(() => {
        if(stable.token.address && stable.token.address[56] === "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"){
            return ETHER;
        }
        return new Token(56, (stable.token.address ? stable.token?.address[56] : ""), 18, stable.token.symbol);
    }, [stable.token.symbol, stable.token.address]);

    const currency1 = useMemo(() => {
        if(stable.quoteToken.address && stable.quoteToken.address[56] === "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c"){
            return ETHER;
        }
        return new Token(56, (stable.quoteToken.address ? stable.quoteToken?.address[56] : ""), 18, stable.quoteToken.symbol);
    }, [stable.quoteToken.symbol, stable.quoteToken.address]);

    return(
        <Box maxWidth={['calc(100% - 15px)', 'calc(100% - 15px)',  'calc(50% - 15px)', 'calc(33% - 15px)']} width="100%">
            <StableCard>
                {withRainbow && <Box className="rainbow" />}
                <CardBody>
                    <Heading currency0={currency0} currency1={currency1} label={stable.lpSymbol} multiplier={stable.multiplier ?? '0'} noFees={stable.depositFee === "0"}/>
                    <AutoColumn mt={30} gap="12px">
                        <RowBetween>
                            <Text fontSize={15}>APR:</Text>
                            <Flex alignItems="center">
                                {stable.apr ? (
                                    <>
                                        <ApyButton
                                            lpLabel={stable.lpSymbol}
                                            apr={stable.apr}
                                            ponyPrice={ponyPrice}
                                            addLiquidityUrl={getLiquidityUrlPathParts({quoteTokenAddress: stable.quoteToken.address, tokenAddress: stable.token.address})}
                                        />
                                        <Text fontWeight="bold" fontSize={16}>{stable.apr.toFixed(2)}%</Text>
                                    </>
                                )
                                :
                                   <Skeleton width={100} height={20} />
                                }
                            </Flex>
                        </RowBetween>
                        <PaddockTable label="Earn" result="PONY" />
                        <PaddockTable label="Deposit fee" result={stable.depositFee ?? ""} />
                        <PaddockTable label="Harvest  lockup" result={stable.harvestInterval ?? ""} />
                        <PaddockTable label="LP-Type" result="PONY-LP" />
                    </AutoColumn>
                    <CardActions stable={stable} account={account ?? ""} addLiquidityUrl={getLiquidityUrlPathParts({quoteTokenAddress: stable.quoteToken.address, tokenAddress: stable.token.address})} />
                </CardBody>
            </StableCard>
        </Box>
    )
}