import { Alert, Button, CardBody, CardHeader, ToastContainer, Toast} from "@ponyswapdex/uikit";
import React, { useCallback, useEffect, useMemo } from "react";
import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
import Card from 'components/Card';
import { Box, Flex, Text } from "rebass";
import useTheme from "hooks/useTheme";
import { AutoColumn, ColumnCenter } from "components/Column";
import Row, {AutoRow} from "components/Row";
import PageBanner from "components/PageBanner";
import { useActiveWeb3React } from "hooks";
import ConnectWalletButton from "components/ConnectWalletButton";
import useCryptoJS from "hooks/useCryptoJS";
import { useContract } from "hooks/useContract";
import REFERRAL_ABI from "constants/abis/PonyReferral.json";
import { BigNumber, Contract } from "ethers";
import { parseEther } from "ethers/lib/utils";

const Referrals = () => {

    const {theme} = useTheme();
    const {account}: any = useActiveWeb3React();
    const {encrypt} = useCryptoJS();
    const contract = useContract('0x66eCCFDa3ae0C322E30D9cC1aa1dCeffaB15cE95', REFERRAL_ABI);
    const [referralsCount, setReferralsCount] = React.useState<number | string>("");
    const [referralsCommissions, setReferralsCommissions] = React.useState<number | string>("");
    const copyToClipboard = (e: string) => navigator.clipboard.writeText(e).then(() => handleClick());

    const [toasts, setToasts] = React.useState<Toast[]>([]);

    const handleClick = () => {
        const now = Date.now();
        const randomToast: Toast = {
            id: `id-${now}`,
            title: `Copied to clipboard`,
            type: 'info',
        };

        setToasts((prevToasts: any) => [randomToast, ...prevToasts]);
    };

    const handleRemove = (id: string) => {
        setToasts((prevToasts: any) => prevToasts.filter((prevToast) => prevToast.id !== id));
    };

    const getRefferals = useCallback(async function(){
        try{
            const count = await contract?.referralsCount(account).toNumber();
            setReferralsCount(count);
            return count;
        }catch(e){
            setReferralsCount("You have no referrals");
            return "You have no referrals";
        }
    }, [contract, account]);

    const getRefferalsRewards = useCallback(async function(){
        try{
            const count = await contract?.totalReferralCommissions(account).toNumber();
            setReferralsCommissions(count);
            return count;
        }catch(e){
            setReferralsCommissions(Number(0).toFixed(4));
            return "You have no referrals";
        }
    }, [contract, account]);

    useEffect(() => {
        getRefferals();
        getRefferalsRewards();
    }, [getRefferals, getRefferalsRewards]);
    
    const encrypted = encrypt(account);

    return(
        <>
        <Flex alignSelf="start" width="100%" flexDirection="column" alignItems="center" height='100%'>
            <PageBanner bgColor="#1a1e2b" title="Referrals" description="Share the referral link below to invite your friends and earn 1% of your friends' earnings FOREVER in PONY!" />
            <AutoColumn gap="40px" maxWidth='1220px' width='100%' px={4}> 
            {account &&
                <Row justify="stretch" gap='40px' width="100%">
                    <Card>
                        <CardHeader>
                            <Text>Number of referrals</Text>
                        </CardHeader>
                        <CardBody p="40px 24px 24px">
                            <Text fontWeight="bold">{referralsCount}</Text>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <Text>Total Commissions</Text>
                        </CardHeader>
                        <CardBody p="40px 24px 24px">
                            <Text fontWeight="bold">{referralsCommissions} PONY</Text>
                        </CardBody>
                    </Card>
                </Row>
                }
                <Card>
                    {account && 
                        <CardHeader>
                            <Flex alignItems="center" justifyContent="space-between">
                                <Text>Your referral link</Text>
                                <Button onClick={() => copyToClipboard(`https://ponyswap.org/?ref=${encrypted.toString() ?? ""}`)} size="sm" variant="secondary" mt={2}>Copy</Button>
                            </Flex>
                        </CardHeader>
                    }
                    <CardBody p='40px 24px'>
                        <Flex alignItems="center" justifyContent="center" flexDirection="column" width="100%">
                            {account ?
                                <ColumnCenter>
                                    <Text>Share the link below to invite your friends!</Text>
                                    <Text sx={{'&:hover': {cursor: "pointer"}}} onClick={() => copyToClipboard(`https://ponyswap.org/?ref=${encrypted.toString() ?? ""}`)} mt={20} color={theme.colors.primary} >
                                        https://ponyswap.org/?ref={encrypted.toString() ?? ""}
                                    </Text>
                                 </ColumnCenter>
                                 :
                                <>
                                    <ConnectWalletButton />
                                    <Text mt={30}>Connect your wallet to start using the referral program</Text>
                                </>
                            }
                        </Flex>
                    </CardBody>
                </Card>
            </AutoColumn>
        </Flex>
         <ToastContainer toasts={toasts} onRemove={handleRemove} />
         </>
    )

}

export default Referrals;