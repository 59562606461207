export const dexStats: {title: string, value: string}[] = [
    {
        title: "Total Liquidity",
        value: "0",
    },
    {
        title: "Total Liquidity Yield",
        value: "0.5%",
    },
];

export const tokenStats: {title: string, value: string}[] = [
    {
        title: "Market Cap",
        value: "$0",
    },
    {
        title: "Total Minted",
        value: "0",
    },
    {
        title: 'Total Burned',
        value: '0',
    },
    {
        title: "Total Locked Rewards",
        value: "0",
    },
    {
        title: "Circulating Supply",
        value: "0",
    },
    {
        title: "Transfer Tax",
        value: "2.0%",
    },
    {
        title: "Max Tx Amount",
        value: "0",
    }
];

