import tokens from '../tokens'
import { PoolConfig, PoolCategory } from '../types'

const pools: PoolConfig[] = [
    {
    sousId: 9,
    stakingToken: tokens.panther,
    earningToken: tokens.panther,
    contractAddress: {
        97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
        56: '0x058451C62B96c594aD984370eDA8B6FD7197bbd4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    },
]

export default pools
